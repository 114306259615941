export const HOME_URL = "https://gkdevelopers.in/backend/"
export const PROJECT_URL = HOME_URL+"wp-json/wp/v2/project"
export const TESTIMONIAL_URL = HOME_URL+"wp-json/wp/v2/testimonial"
export const TEAM_URL = HOME_URL+"wp-json/wp/v2/team"
export const TEAM_URL_SINGLE = HOME_URL+"wp-json/wp/v2/our-team?slug="
export const BLOG_URL = HOME_URL+"wp-json/wp/v2/posts/"
export const CAT_URL = HOME_URL+"wp-json/wp/v2/categories"
export const CONTACT_FORM= HOME_URL+'wp-json/contact-form-7/v1/contact-forms/'


